import type { AccessorKeyColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

import type { FilterProperty } from '@/types/advanceFilter';

import { createFields } from '../queryBuilder/propertiesUtils';

export const useColumns = <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumns: () => AccessorKeyColumnDef<T, any>[]
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useMemo<AccessorKeyColumnDef<T, any>[]>(
    () => getColumns(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export const useFields = (properties: FilterProperty[]) => {
  return useMemo(
    () => createFields(properties),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
