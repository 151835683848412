import { Button, Dialog, IconButton, Stack, Typography } from '@mui/material';

export interface InventoryBulkActionDialogProps {
  title: string;
  description: string;
  onCancel: () => void;
  onConfirm: () => void;
  buttonIconClass: string;
  buttonTitle: string;
  cancelButtonTitle?: string;
}

export default function InventoryBulkActionDialog(
  props: InventoryBulkActionDialogProps
) {
  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          width: '840px',
        },
      }}
    >
      <Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          padding='16px 24px'
          paddingBottom='0'
        >
          <div></div>
          <IconButton onClick={props.onCancel} sx={{ borderRadius: '4px' }}>
            <i
              onClick={props.onCancel}
              className='material-symbols-close-rounded'
            />
          </IconButton>
        </Stack>
        <Stack
          alignItems='center'
          gap='12px'
          sx={{ px: '40px', textAlign: 'center', pb: '40px' }}
        >
          <Typography variant='h2'>{props.title}</Typography>
          <Typography variant='body1'>{props.description}</Typography>
          <Stack direction='row' gap='12px' className='mt-[12px]'>
            <Button onClick={props.onCancel}>
              {props.cancelButtonTitle ?? 'Cancel'}
            </Button>
            <Button onClick={props.onConfirm} variant='outlined'>
              <i className={props.buttonIconClass}></i>
              <Typography marginLeft='8px'>{props.buttonTitle}</Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
}
