import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import type { BackupPolicy } from '@repo/api-gw-sdk';
import { useState } from 'react';

import BackupPolicyTag from '@/components/backupPolicy/BackupPolicyTag';
import { SidePanelWrapper } from '@/components/layout/sidePanel/sidePanelWrapper';
import { useDAL } from '@/data/dal';

import Loading from './loading';

export interface InventoryBulkPolicyAssignmentDialogProps {
  onClose: () => void;
  resourceIds: string[];
}

export default function InventoryBulkPolicyAssignmentDialog(
  props: InventoryBulkPolicyAssignmentDialogProps
) {
  const dal = useDAL();
  const { body, isLoading } = dal.backupPolicy.list();
  const [selectedPolicies, setSelectedPolicies] = useState<BackupPolicy[]>([]);
  const [updating, setUpdating] = useState(false);

  const assignToPolicy = async () => {
    try {
      setUpdating(true);

      for (const policy of selectedPolicies) {
        for (const resourceId of props.resourceIds) {
          if (policy.exclude?.includes(resourceId)) {
            policy.exclude = policy.exclude.filter((id) => id !== resourceId);
          }

          if (!policy.include?.includes(resourceId)) {
            policy.include = [...policy.include, resourceId];
          }
        }

        await dal.backupPolicy.update(policy);
      }
    } finally {
      props.onClose();
    }
  };

  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          width: '840px',
        },
      }}
    >
      <SidePanelWrapper
        title='Assign to backup policy'
        close={() => {
          if (updating) return;
          props.onClose();
        }}
        hideBorder
      >
        <Box className='h-full flex flex-col px-[40px] pt-0'>
          <Typography variant='body1' sx={{ pt: '8px' }}>
            Choose the backup policies you want to assign the selected resources
            to.
          </Typography>
          <Divider sx={{ mx: '-40px', my: '20px' }} />
          {isLoading && <Loading />}
          {!isLoading && (
            <Box
              sx={{
                maxHeight: '300px',
                overflow: 'auto',
              }}
            >
              {body?.backupPolicies.map((policy) => (
                <Stack
                  direction='row'
                  alignItems='center'
                  key={policy.id}
                  sx={{ py: '4px' }}
                >
                  <Checkbox
                    value={policy.id}
                    checked={selectedPolicies.some((p) => p.id === policy.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedPolicies([...selectedPolicies, policy]);
                      } else {
                        setSelectedPolicies(
                          selectedPolicies.filter((p) => p.id !== policy.id)
                        );
                      }
                    }}
                  />
                  <BackupPolicyTag policy={policy} />
                </Stack>
              ))}
            </Box>
          )}
        </Box>
        <Box className='flex justify-end px-[20px] py-[16px]'>
          <Button
            disabled={isLoading || updating}
            size='small'
            className='mr-[12px]'
            onClick={() => {
              props.onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading || updating}
            size='small'
            variant='outlined'
            onClick={() => {
              void assignToPolicy();
            }}
          >
            {updating ? (
              <i className='line-md-loading-loop w-[20px] h-[20px]' />
            ) : (
              'Assign'
            )}
          </Button>
        </Box>
      </SidePanelWrapper>
    </Dialog>
  );
}
