import type { InventoryResource } from '@repo/api-gw-sdk';

import { useDAL } from '@/data/dal';

import { RestartAutoClassificationPanel } from './resumeAutoClassifcationPanel';

export function RestartAutomaticEnvironmentDetection({
  open,
  onClose,
  entities,
}: {
  open: boolean;
  onClose: () => void;
  entities: InventoryResource[];
}) {
  const dal = useDAL();

  const onSave = () => {
    void Promise.all(
      entities.map((entity) =>
        dal.inventory.environments.deleteOverride(entity.id).then(() => {
          entity.classifications = entity.classifications || {};
          entity.classifications.environmentDetails =
            entity.classifications.environmentDetails || {};
          entity.classifications.environmentDetails.isOverridden = false;
        })
      )
    ).finally(onClose);
  };
  if (!open) {
    return null;
  }
  return (
    <>
      <RestartAutoClassificationPanel
        open={open}
        onClose={onClose}
        onSave={onSave}
        title='Resume auto-classification for environment'
        description='Eon will automatically classify the selected resources, which removes any overrides. This could change the applied backup policies and controls.'
      />
    </>
  );
}
