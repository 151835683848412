import {
  Box,
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Button,
  Dialog,
} from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';
import { Environment } from '@repo/api-gw-sdk';
import { useState } from 'react';

import { Icon } from '@/components/shared/icon';
import { useDAL } from '@/data/dal';
import { Environments } from '@/data/inventory/data';

function getUnifiedEnvironment(entities: InventoryResource[]) {
  const environment =
    entities[0].classifications?.environmentDetails?.environment;
  if (
    entities.every(
      (entity) =>
        entity.classifications?.environmentDetails?.environment === environment
    )
  ) {
    return environment;
  }

  return null;
}

export function EnvironmentEditor({
  open,
  onClose,
  entities,
}: {
  open: boolean;
  onClose: () => void;
  entities: InventoryResource[];
}) {
  const [environment, setEnvironment] = useState(
    getUnifiedEnvironment(entities)
  );
  const [updating, setUpdating] = useState(false);
  const dal = useDAL();

  const onSave = () => {
    if (!environment) return;
    setUpdating(true);

    void Promise.all(
      entities.map((entity) =>
        dal.inventory.environments.override(entity.id, environment).then(() => {
          entity.classifications = entity.classifications || {};
          entity.classifications.environmentDetails =
            entity.classifications.environmentDetails || {};

          entity.classifications.environmentDetails.environment = environment;
          entity.classifications.environmentDetails.isOverridden = true;
        })
      )
    ).finally(() => {
      setUpdating(false);
      onClose();
    });
  };
  if (!open) {
    return null;
  }
  return (
    <Dialog open={open}>
      <Box
        sx={{
          m: 0,
          p: 0,
        }}
      >
        <Stack className='h-full'>
          <Stack
            justifyContent='space-between'
            direction='row'
            alignItems='center'
            padding='16px 24px'
          >
            <Stack alignItems='center' direction='row'>
              <div className='flex flex-col'>
                <Typography className='font-semibold'>
                  Override environment
                </Typography>
              </div>
            </Stack>
            <div className='flex gap-4'>
              <Icon
                onClick={onClose}
                iconClass='material-symbols-close-rounded'
              />
            </div>
          </Stack>
          <Box
            sx={{
              paddingLeft: '40px',
              paddingRight: '40px',
              maxWidth: '630px',
            }}
          >
            {' '}
            <Typography sx={{ lineHeight: '20px' }} className='font-light'>
              {`Eon won't automatically classify environment for the selected resources. This could change the applied backup policies and controls.`}
            </Typography>
          </Box>{' '}
          <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
          <Box sx={{ marginLeft: '24px' }}>
            <RadioGroup>
              {Object.values(Environment)
                .filter((x) => x !== Environment.UnspecifiedEnvironment)
                .map((x) => (
                  <div key={x}>
                    <FormControlLabel
                      title={Environments[x].title}
                      className='w-full'
                      control={
                        <Radio
                          sx={{ padding: '10px 10px 10px 30px' }}
                          size='small'
                          checked={x === environment}
                          onChange={() => {
                            setEnvironment(x);
                          }}
                        />
                      }
                      label={Environments[x].title}
                    />
                  </div>
                ))}
            </RadioGroup>
          </Box>
          {/* Create two buttons at the bottom, aligned to the right */}
          <Stack
            justifyContent='flex-end'
            direction='row'
            marginTop='12px'
            padding='16px 24px'
          >
            <Box sx={{ marginRight: '12px' }}>
              <Button onClick={onClose}>
                <Typography
                  className='font-semibold'
                  sx={{ color: 'var(--mui-palette-primary-main)' }}
                >
                  Cancel
                </Typography>
              </Button>
            </Box>
            <Box>
              <Button
                onClick={onSave}
                variant='outlined'
                disabled={environment === null}
              >
                {updating ? (
                  <i className='line-md-loading-loop w-[20px] h-[20px]' />
                ) : (
                  <Typography
                    className='font-semibold'
                    sx={{ color: 'var(--mui-palette-primary-main)' }}
                  >
                    Override
                  </Typography>
                )}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
}
